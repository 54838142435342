import React from "react"

export const GearOption = () => (
  <svg width="28" height="28" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m24.1594 10.5859.1332.0475C28 11.9569 28 12.1111 28.0001 12.4844v2.9072c0 .3728 0 .5129-3.7055 1.9501l-.1242.0487-.5201 1.2349.0618.1313c1.6606 3.4947 1.5509 3.6042 1.2861 3.8686l-.0003.0003-2.1074 2.061c-.0878.0869-.2406.1433-.3692.1433-.3178 0-1.5441-.4483-3.6437-1.3367l-.1215-.0495-1.2762.5159-.0505.1319c-1.3548 3.6237-1.5095 3.6237-1.8823 3.6237h-2.9694c-.3635 0-.5136 0-1.9744-3.6137l-.0499-.1282-1.28165-.5184-.12441.0582c-3.10655 1.4054-3.46394 1.4054-3.58146 1.4054-.12985 0-.28212-.0604-.37011-.145L3.09694 22.72l-.00041-.0004c-.26796-.2665-.36699-.365 1.20804-3.9388l.05596-.1264-.5211-1.2367-.1325-.0468C0 16.0424 0 15.8874 0 15.514v-2.9089c0-.3724 0-.5118 3.69958-1.9415l.12751-.0493.52102-1.24037-.06095-.12978C2.62878 5.74476 2.73948 5.63451 3.00638 5.37124l2.10398-2.05651c.08632-.08503.23776-.14326.36754-.14326.31983 0 1.54676.44924 3.64809 1.33514l.12183.05066 1.27418-.51943.051-.13182C11.9267.28479 12.0916.28479 12.4535.28479h2.9685c.3633 0 .5129 0 1.9728 3.61494l.0514.12751 1.2808.51579.1245-.05588c3.1045-1.40609 3.4628-1.40609 3.581-1.40609.1301 0 .281.05997.367.14523l2.102 2.0506.0001.00017c.2613.25899.3696.36632-1.2059 3.93953l-.0565.12759.5202 1.24172ZM14 17.2307c1.7843 0 3.2307-1.4465 3.2307-3.2308S15.7843 10.7691 14 10.7691c-1.7843 0-3.2308 1.4465-3.2308 3.2308S12.2157 17.2307 14 17.2307Z"
      fill="#fff"
    />
  </svg>
)
