import React from "react"

export const Globe: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_2566_17462)">
            <path d="M3.15929 18.6598C4.57732 18.0152 6.12425 17.5855 7.75713 17.2847C7.45634 15.9097 7.28445 14.4916 7.24148 13.0306H0.237305C0.366216 15.2651 1.09671 17.2847 2.29989 19.0035L3.15929 18.6598Z" fill="#657097" />
            <path d="M3.71791 20.0346L3.20227 20.2494C5.09297 22.398 7.75713 23.816 10.7221 24.1598L10.5932 23.9879C9.51892 22.5269 8.65951 20.7221 8.1009 18.7455C6.51099 19.0033 5.05 19.433 3.71791 20.0346Z" fill="#657097" />
            <path d="M8.05793 5.98337C8.57357 4.13564 9.34704 2.41682 10.3354 0.998799L10.7221 0.483154C7.71417 0.826918 5.09297 2.28791 3.1593 4.43643L3.63198 4.65129C5.00703 5.25287 6.51099 5.72555 8.05793 5.98337Z" fill="#657097" />
            <path d="M7.71416 7.40119C6.03831 7.1004 4.49138 6.62772 3.03038 5.98317L2.25692 5.6394C1.09671 7.35822 0.366216 9.4208 0.237305 11.6123H7.24148C7.28445 10.1513 7.41337 8.73327 7.71416 7.40119Z" fill="#657097" />
            <path d="M20.8631 20.3784L20.5623 20.1635C19.1013 19.476 17.4684 19.0033 15.7496 18.7025C15.191 20.6792 14.3316 22.4839 13.2573 23.9449L13.0854 24.2027C16.1793 23.9449 18.8865 22.5269 20.8631 20.3784Z" fill="#657097" />
            <path d="M20.6053 4.52237L20.949 4.35049C18.9724 2.159 16.2223 0.740977 13.1284 0.483154L13.5152 1.04177C14.5035 2.45979 15.234 4.17861 15.7926 6.02634C17.5114 5.72554 19.1443 5.2099 20.6053 4.52237Z" fill="#657097" />
            <path d="M8.65955 11.5696H15.1481C15.1051 10.1946 14.9332 8.86248 14.6754 7.61633C13.773 7.70227 12.8706 7.78822 11.9683 7.78822C11.0229 7.78822 10.0776 7.74524 9.17519 7.61633C8.8744 8.86248 8.70252 10.2375 8.65955 11.5696Z" fill="#657097" />
            <path d="M21.8514 5.46777L21.2498 5.81154C19.7029 6.54203 17.9411 7.10065 16.1364 7.40144C16.4371 8.73353 16.5661 10.1516 16.609 11.5696H23.9999C23.871 9.33511 23.0976 7.22956 21.8514 5.46777Z" fill="#657097" />
            <path d="M21.2928 18.9176L21.8085 19.2613C23.0976 17.4996 23.871 15.351 23.9999 13.0736H16.609C16.5661 14.5346 16.3942 15.9526 16.0934 17.3277C17.9411 17.6285 19.6599 18.1871 21.2069 18.9176H21.2928Z" fill="#657097" />
            <path d="M11.9253 1.29944L11.4956 1.85805C10.6362 3.06123 9.9916 4.56519 9.51892 6.19807C10.3354 6.28401 11.1518 6.32698 11.9682 6.32698C12.7847 6.32698 13.5581 6.28401 14.3316 6.19807C13.8589 4.56519 13.1714 3.06123 12.312 1.81508L11.9253 1.29944Z" fill="#657097" />
            <path d="M11.9253 23.3436L12.0971 23.1287C13.0425 21.8396 13.773 20.2497 14.2886 18.5309C13.5152 18.4449 12.7417 18.402 11.9682 18.402C11.1518 18.402 10.3354 18.4449 9.56189 18.5309C10.0775 20.2497 10.808 21.8396 11.7534 23.0858L11.9253 23.3436Z" fill="#657097" />
            <path d="M11.9683 16.9409C12.8706 16.9409 13.773 16.9839 14.6754 17.1128C14.9762 15.8237 15.1051 14.4487 15.1481 13.0736H8.65955C8.70252 14.4916 8.8744 15.8237 9.13222 17.1128C10.0776 16.9839 11.0229 16.9409 11.9683 16.9409Z" fill="#657097" />
        </g>
        <defs>
            <clipPath id="clip0_2566_17462">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
