import React from "react"
import { Monitoring } from "./icons/Monitoring"
import { Cloud } from "./icons/Cloud"
import { GearOption } from "./icons/GearOption"
import { Change } from "./icons/Change"
import { Design } from "./icons/Design"
import { Web } from "./icons/Web"
import { Phone } from "./icons/Phone"
import { ML } from "./icons/ML"
import { StaticImage } from "gatsby-plugin-image"
const first = "../../images/expNServ/first.png"
const second = "../../images/expNServ/second.png"
const third = "../../images/expNServ/third.png"
const fourth = "../../images/expNServ/fourth.png"
const fifth = "../../images/expNServ/fifth.png"
const sixth = "../../images/expNServ/sixth.png"
const seventh = "../../images/expNServ/seventh.png"
const eighth = "../../images/expNServ/eighth.png"

export type ExpNServDataItem = {
  icon: React.FC
  text: string
  background: React.ReactElement
  link: string
}
export const data: ExpNServDataItem[] = [
  {
    icon: Monitoring,
    text: "Monitoring and Control Systems",
    background: <StaticImage placeholder={"blurred"} src={first} alt={""}/>,
    link: "/technologies#solutions"
  },
  {
    icon: Cloud,
    text: "Cloud Architecture and Solutions",
    background: <StaticImage placeholder={"blurred"} src={second} alt={""}/>,
    link: "/technologies#cloud"
  },
  {
    icon: GearOption,
    text: "Embedded Systems",
    background: <StaticImage placeholder={"blurred"} src={third} alt={""}/>,
    link: "/technologies#embedded-systems"
  },
  {
    icon: Change,
    text: "Continious Delivery",
    background: <StaticImage placeholder={"blurred"} src={fourth} alt={""}/>,
    link: "/technologies#continous-delivery"
  },
  {
    icon: Design,
    text: "UX/UI Design",
    background: <StaticImage placeholder={"blurred"} src={fifth} alt={""}/>,
    link: "/technologies#ux"
  },
  {
    icon: Web,
    text: "Web Applications",
    background: <StaticImage placeholder={"blurred"} src={sixth} alt={""}/>,
    link: "/technologies#web"
  },
  {
    icon: Phone,
    text: "Mobile Applications",
    background: <StaticImage placeholder={"blurred"} src={seventh} alt={""}/>,
    link: "/technologies#mobile"
  },
  {
    icon: ML,
    text: "Machine Learning",
    background: <StaticImage placeholder={"blurred"} src={eighth} alt={""}/>,
    link: "/technologies#machine-learning"
  },
]
