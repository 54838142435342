import React from "react"

export const Check: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="12" fill="#471EEB" />
    <path d="M6.69995 12.5L10.2 16L18.7 7.5" stroke="#fff" strokeWidth="1.45" />
  </svg>
)
