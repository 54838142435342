import React from "react"

export const Change = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M0 6.67692h14.1795V0L28 14 14.1795 28v-6.6769H0V6.67692z"
      fill="#fff"
    />
  </svg>
)
