import { domAnimation, LazyMotion, m } from "framer-motion"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import { ExpNServDataItem } from "../expNServData"
import * as styles from "./ExpertiseCard.module.scss"

type ExpertiseProps = {
  item: ExpNServDataItem
}
export const ExpertiseCard: React.FC<ExpertiseProps> = ({ item }) => {
  return (
    <LazyMotion features={domAnimation}>
      <AnchorLink to={item.link}>
        <div className={styles.card}>
          <m.div className={styles.background} whileHover={{ scale: 1.1 }}>
            {item.background}
          </m.div>
          <div className={styles.icon}>
            <item.icon />
          </div>
          <div className={styles.text}>{item.text}</div>
        </div>
      </AnchorLink>
    </LazyMotion>
  )
}
