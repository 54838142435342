// extracted by mini-css-extract-plugin
export var bottomLink = "ExpertiseNServicesPage-module--bottomLink--54e47";
export var bottomRight = "ExpertiseNServicesPage-module--bottomRight--eb0db";
export var bottomText = "ExpertiseNServicesPage-module--bottomText--ba97d";
export var button = "ExpertiseNServicesPage-module--button--1c9aa";
export var cards = "ExpertiseNServicesPage-module--cards--39ed5";
export var container = "ExpertiseNServicesPage-module--container--9a9f6";
export var details = "ExpertiseNServicesPage-module--details--215d1";
export var h2 = "ExpertiseNServicesPage-module--h2--42195";
export var icon = "ExpertiseNServicesPage-module--icon--75e85";
export var iconImage = "ExpertiseNServicesPage-module--iconImage--235de";
export var info = "ExpertiseNServicesPage-module--info--07fa1";
export var main = "ExpertiseNServicesPage-module--main--f5671";
export var right = "ExpertiseNServicesPage-module--right--c2f7a";
export var rightHeading = "ExpertiseNServicesPage-module--rightHeading--ad095";
export var servicesText = "ExpertiseNServicesPage-module--servicesText--e6d29";
export var title = "ExpertiseNServicesPage-module--title--f08cf";
export var waysToWork = "ExpertiseNServicesPage-module--waysToWork--e5d59";
export var waysToWorkItem = "ExpertiseNServicesPage-module--waysToWorkItem--16f6a";