import Tippy from "@tippyjs/react";
import React from "react";
import { Icon } from "../icons";
import { TechonologyStack } from "../TechnologyStack/TechnologyStack";
import * as styles from "./ProjectCard.module.scss";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export interface ProjectCardProps {
    backgroundImage: React.ReactElement,
    text: string,
    icons: Icon[]
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ backgroundImage, text, icons }) => {
    return (
        <Tippy
            placement="bottom-end"
            offset={[0, 0]}
            content={<TechonologyStack technologies={icons.map(icon => icon.name)} />}
            theme="light"
            arrow={false}
        >
            <div className={styles.container}>
                <div className={styles.background}>
                    {backgroundImage}
                </div>
                <div className={styles.project}>
                    <h4 className={styles.projectText}>{text}</h4>
                    <div className={styles.projectIcons}>
                        {icons.map(icon =>
                            <div className={styles.image}>
                                {icon.image}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Tippy>
    )
}