import classNames from "classnames"
import React from "react"
import AdvantageIndicator from "../../AdvantageIndicator"
import { Icon } from "../../AdvantageIndicator/AdvantageIndicator"
import * as styles from "./ColumnData.module.scss"

export interface ColumnDataProps {
    icon?: Icon;
    text?: string;
    textClassName?: string;
}

export const ColumnData: React.FC<ColumnDataProps> = ({ icon, text, textClassName }) => {
    return (
        <div className={styles.container}>
            <div className={styles.columnData}>
                {icon && (
                    <div className={styles.columnDataIcon}>
                        <AdvantageIndicator icon={icon} />
                    </div>
                )}

                {text && (
                    <h6 className={classNames(styles.columnDataText, textClassName)}>
                        {text}
                    </h6>
                )}
            </div>
        </div>
    )
}