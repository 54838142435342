import React from "react"

export const Phone = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.20001 2c0-1.104569.89543-2 2-2H21.8c1.1046 0 2 .895431 2 2v24c0 1.1046-.8954 2-2 2H6.20001c-1.10456 0-2-.8954-2-2V2zm4.2.8H19.6v1.4H8.40001V2.8zM14 20.3c-1.1598 0-2.1.9402-2.1 2.1 0 1.1598.9402 2.1 2.1 2.1 1.1598 0 2.1-.9402 2.1-2.1 0-1.1598-.9402-2.1-2.1-2.1z"
      fill="#fff"
    />
  </svg>
)
