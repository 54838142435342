import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as icons from "../icons";
import { ProjectCardProps } from "../ProjectCard/ProjectCard";

const icons_path = "../../../images/projects/"

export const cards: ProjectCardProps[] = [
    {
        backgroundImage: <StaticImage placeholder="tracedSVG" src={icons_path + "atom.png"} alt="" />,
        text: "Control and Administration for Quantum Computers",
        icons: [icons.java, icons.kotlin, icons.go, icons.react, icons.electron, icons.figma]
    },
    {
        backgroundImage: <StaticImage placeholder="tracedSVG" src={icons_path + "brain.png"} alt="" />,
        text: "Network Anomalies Predictions AI/ML",
        icons: [icons.python, icons.pandas, icons.scikit, icons.scipy, icons.docker]
    },
    {
        backgroundImage: <StaticImage placeholder="tracedSVG" src={icons_path + "storage.png"} alt="" />,
        text: "Storage System",
        icons: [icons.python, icons.angular, icons.react, icons.ml, icons.elasticsearch]
    },
    {
        backgroundImage: <StaticImage placeholder="tracedSVG" src={icons_path + "flame.png"} alt="" />,
        text: "Firewall and Security System",
        icons: [icons.python, icons.go, icons.react, icons.postgresql, icons.docker, icons.kubernetes]
    },
    {
        backgroundImage: <StaticImage placeholder="tracedSVG" src={icons_path + "network.png"} alt="" />,
        text: "Network / Service Monitoring and Control",
        icons: [icons.java, icons.go, icons.timescale, icons.aws]
    },
    {
        backgroundImage: <StaticImage placeholder="tracedSVG" src={icons_path + "security.png"} alt="" />,
        text: "HomeLand Security",
        icons: [icons.python, icons.angular, icons.flask, icons.opencv, icons.docker]
    },
]