import React from "react"

export const Cross = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect
      x="4.49988"
      y="5.5"
      width="1.6"
      height="14.1421"
      rx=".8"
      transform="rotate(-45 4.49988 5.5)"
      fill="#657097"
    />
    <rect
      x="14.4999"
      y="4.5"
      width="1.6"
      height="14.1421"
      rx=".8"
      transform="rotate(45 14.4999 4.5)"
      fill="#657097"
    />
  </svg>
)
