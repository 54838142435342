import React from "react"
import ContactUsPage from "../components/ContactUs/ContactUsPage"
import ExpertiseNServicesPage from "../components/ExpertiseAndServices/ExpertiseNServicesPage"
import HomePage from "../components/HomePage"
import JoinUsPage from "../components/JoinUs/JoinUsPage"
import Layout from "../components/Layout/Layout"
import RemoteTeamsPage from "../components/RemoteTeams/RemoteTeamsPage"
import SEO from "../components/SEO"

const IndexPage: React.FC = () => (
  <Layout>
    <div>
      <SEO
        title="V4Scale"
        description="V4Scale is an Israeli company that expands the local R&D capabilities of leading Israeli and US technology companies with their diverse and skilled remote workforce worldwide."
      />
      <HomePage />
      <RemoteTeamsPage />
      <ExpertiseNServicesPage />
      <JoinUsPage />
      <ContactUsPage />
    </div>
  </Layout>
)

export default IndexPage
