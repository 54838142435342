import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../Button"
import * as styles from "./ContactUsPage.module.scss"

export const ContactUsPage: React.FC = () => {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.main} id="contact-us">
      <section>
        <div className={styles.container}>
          <div className={styles.half}>
            <h2 className={styles.h2}>Contacts</h2>
            <div className={styles.contacts}>
              <div className={styles.contact}>
                <div className={styles.locationItem}>Tel Aviv, Israel</div>
                <div className={styles.locationItem}>Tbilisi, Georgia</div>
              </div>
              <div className={styles.emails}>
                <div className={styles.contact}>
                  <div className={styles.emailLabel}>Business</div>
                  <a
                    className={styles.emailLink}
                    href="mailto:info@v4scale.com"
                  >
                    info@v4scale.com
                  </a>
                </div>
                <div className={styles.contact}>
                  <div className={styles.emailLabel}>Join Us</div>
                  <a className={styles.emailLink} href="mailto:jobs@v4scale.com">
                    jobs@v4scale.com
                  </a>
                </div>
              </div>
            </div>
            <Button
              text="Contact Us"
              color="yellow"
              onClick={() => window.location.href = "mailto:info@v4scale.com"}
              className={styles.btn}
            />
          </div>
          <div className={styles.background}>
            {!breakpoints.md && (
              <StaticImage
                src={"../images/stairs.png"}
                alt={""}
                placeholder={"blurred"}
                formats={["webp", "avif"]}
                className={styles.backgroundImage}
                quality={95}
              />
            )}
            {breakpoints.md && (
              <StaticImage
                src={"../images/stairs2.png"}
                alt={""}
                placeholder={"blurred"}
                formats={["webp", "avif"]}
                quality={95}
                className={styles.backgroundImage}
                objectFit={"contain"}
                objectPosition={"right bottom"}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  )
}
