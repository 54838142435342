import { graphql, useStaticQuery } from "gatsby"

interface HomePageText {
  titleFirstLine: string
  titleSecondLinePlainText: string
  titleSecondLineColoredText: string
  fullDetails: { childMarkdownRemark: { html: string } }
  shortDetails: { childMarkdownRemark: { html: string } }
}

export const fetchHomePageTextQuery = (): HomePageText => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePageText(slug: { eq: "home-page" }) {
        titleFirstLine
        titleSecondLinePlainText
        titleSecondLineColoredText
        fullDetails {
          childMarkdownRemark {
            html
          }
        }
        shortDetails {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)
  return data.contentfulHomePageText
}
