import React from "react"

export const ML = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.73438 0C11.0208 0 12.2013.453213 13.125 1.20868V26.7913C12.2013 27.5468 11.0208 28 9.73438 28c-2.95351 0-5.34901-2.3891-5.35935-5.3402C1.88528 22.1976 0 20.0143 0 17.3906 0 16.1042.453213 14.9237 1.20868 14 .453213 13.0763 0 11.8958 0 10.6094c0-2.62368 1.88528-4.80701 4.37503-5.2692C4.38537 2.38912 6.78087 0 9.73438 0zm8.96872 0c-1.2864 0-2.4669.453213-3.3906 1.20868V12.9062h2.8433c.4267-.7819 1.2563-1.3124 2.2098-1.3124 1.3894 0 2.5157 1.1262 2.5157 2.5156 0 1.3893-1.1263 2.5156-2.5157 2.5156-1.04 0-1.9326-.6311-2.3157-1.5312h-2.7374v11.6975C16.2362 27.5468 17.4167 28 18.7031 28c2.9535 0 5.349-2.3891 5.3594-5.3402 2.4897-.4622 4.375-2.6455 4.375-5.2692 0-1.2864-.4532-2.4669-1.2087-3.3906.7555-.9237 1.2087-2.1042 1.2087-3.3906 0-2.62368-1.8853-4.80701-4.375-5.2692C24.0521 2.38912 21.6566 0 18.7031 0z"
      fill="#fff"
    />
  </svg>
)
