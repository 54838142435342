import React from "react"
import { Icon } from "../../../AdvantageIndicator/AdvantageIndicator"
import * as styles from "./FourthCol.module.scss"
import { V4Scale } from "../../../../V4Scale"
import { m } from "framer-motion"
import { ColProps } from "../FirstCol"
import head from "../../../img/head.png"
import { ColumnData } from "../../ColumnData/ColumnData"

const columnIcon = Icon.CheckWhite
const columnTexts = [
  "Unlimited",
  "Fast",
  "High",
  "Flexible hours",
  "Top Israeli companies",
  "Business trips to Israel"
]

const columnVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    backgroundPositionY: ["calc(50% + 0px)", "calc(50% + 15px)", "calc(50% + 0px)"],
    transition: {
      x: {
        type: "spring",
        stiffness: 100,
        damping: 15,
      },
      backgroundPositionY: {
        repeat: Infinity,
        repeatType: "mirror",
        duration: 4,
        easing: "easeInOut",
        type: "spring",
      },
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
}

export const FourthCol: React.FC<ColProps> = ({ isSelected }) => (
  <>
    {isSelected && (
      <m.div
        className={styles.cell}
        style={{ backgroundImage: `url(${head})` }}
        variants={columnVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className={styles.heading}>
          <V4Scale />
        </div>
        <div className={styles.container}>
          {
            columnTexts.map((text, index) =>
              <ColumnData key={index} icon={columnIcon} text={text} textClassName={styles.columnDataText} />
            )
          }
        </div>
      </m.div>
    )}
  </>
)
