import { LayoutGroup } from "framer-motion"
import React, { useState } from "react"
import { Column } from "../Columns/Column"
import { Columns } from "../Columns/Columns"
import * as styles from "./MobileColumns.module.scss"
import Thumb from "./Thumb"

const columnDatas = [
  { "text": "Skills Growth" },
  { "text": "Career Upside" },
  { "text": "Salary & Rewards" },
  { "text": "Work / Life Balance" },
  { "text": "Stability" },
  { "text": "Opportunity to See the World" }
]

const thumbs = [
  { "text": "Big Company" },
  { "text": "Startup" },
  { "text": "Freelance" },
  { "text": "V4Scale" }
]

export const MobileColumns = () => {
  const [selected, setSelected] = useState(3)
  return (
    <div className={styles.mobColumns}>
      <div className={styles.container}>
        <Column heading="Advantages" columnDatas={columnDatas} />
        {Columns.map((Column, i) => (
          <Column isSelected={selected === i} key={i} />
        ))}
      </div>
      <LayoutGroup>
        <div className={styles.thumbs}>
          {thumbs.map(({ text }, i) => (
            <Thumb
              key={text}
              thumb={text}
              isSelected={selected === i}
              onClick={() => {
                setSelected(i)
              }}
            />
          ))}
        </div>
      </LayoutGroup>
    </div>
  )
}
