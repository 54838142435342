import { useEffect, useState } from "react"

export enum DisplayType {
  Desktop,
  Tablet,
  Mobile,
}

let defaultDisplayType = DisplayType.Desktop

const getDisplayType = () => {
  if (window.innerWidth >= 1080) {
    return DisplayType.Desktop
  } else if (window.innerWidth >= 720) {
    return DisplayType.Tablet
  } else {
    return DisplayType.Mobile
  }
}

if (typeof window !== `undefined`) {
  defaultDisplayType = getDisplayType()
}

export const useDisplayType = () => {
  const [displayType, setDisplayType] = useState(defaultDisplayType)

  useEffect(() => {
    const handler = () => setDisplayType(getDisplayType())

    window.addEventListener(`resize`, handler)
    return () => window.removeEventListener(`resize`, handler)
  }, [])

  return displayType
}
