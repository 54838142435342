import React from "react"

export const Web = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M26.0859 1.42188H1.91406C.858551 1.42188 0 2.28043 0 3.33594V16.1h28V3.33594c0-1.05551-.8586-1.91406-1.9141-1.91406zM0 19.7422c0 1.0555.858551 1.914 1.91406 1.914H26.0859c1.0555 0 1.9141-.8585 1.9141-1.914V18.375H0v1.3672zM19.0859 24.9375h-1.8047v-1.6406h-6.5624v1.6406H8.91406c-.45309 0-.82031.3672-.82031.8203s.36722.8203.82031.8203H19.0859c.4531 0 .8203-.3672.8203-.8203s-.3672-.8203-.8203-.8203z"
      fill="#fff"
    />
  </svg>
)
