import React from "react"
import { Icon } from "../../AdvantageIndicator/AdvantageIndicator"
import { Column } from "./Column"
import { ColProps } from "./FirstCol"

const columnDatas = [
  { "icon": Icon.Cross },
  { "icon": Icon.Cross },
  { "icon": Icon.Question },
  { "icon": Icon.Check },
  { "icon": Icon.Cross },
  { "icon": Icon.Cross }
]

export const ThirdCol: React.FC<ColProps> = ({ isSelected }) => (
  <>
    {isSelected && (
      <Column heading={"Freelance"} columnDatas={columnDatas} />
    )}
  </>
)
