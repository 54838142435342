import { domMax, LazyMotion, m } from "framer-motion"
import { Link, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import React, { useContext } from "react"
import { remoteTeamsPageRoutesContext } from "../../../data/context"
import Button from "../../Button"
import { ProjectCard } from "../ProjectCard/ProjectCard"
import { cards as cardItems } from "./cards"
import * as styles from "./RemoteTeamsPage.module.scss"

export const RemoteTeamsPage: React.FC = () => {
  const breakpoints = useBreakpoint()
  const cards = breakpoints.md ? cardItems.slice(0, 4) : cardItems
  const routes = useContext(remoteTeamsPageRoutesContext)

  const handleOnClickViewVacancies = () => navigate(routes.vacancies.to)

  return (
    <LazyMotion features={domMax}>
      <div className={styles.main} id="remote-teams">
        <section>
          <div className={styles.background}></div>

          <div className={styles.projects}>
            <m.div className={styles.container} style={{ height: "auto" }}>
              <div className={styles.headings}>
                <h3 className={styles.headingsSecondary}>v4teams</h3>
                <h2 className={styles.headingsMain}>
                  Remote <br className={styles.headingsMainBreak} />
                  Development <br className={styles.headingsMainBreak} />
                  Teams
                </h2>
                <h4 className={styles.headingsInformation}>
                  Our Remote Teams are developing cutting-edge SW products in
                  cooperation with the top 50 Israeli technology companies.
                </h4>
                <Button
                  text="View vacancies"
                  color={"yellow"}
                  className={styles.headingsButton}
                  onClick={handleOnClickViewVacancies}
                />
              </div>
              <m.div className={styles.project}>
                <m.div className={styles.projectCards} layout>
                  {cards.map(card => (
                    <ProjectCard
                      backgroundImage={card.backgroundImage}
                      text={card.text}
                      icons={card.icons}
                      key={card.text}
                    />
                  ))}
                </m.div>
                <div className={styles.footer}>
                  <div className={styles.footerText}>
                    13 remote development teams
                    <Link
                      className={styles.footerLink}
                      to={routes.allProjects.to}
                    >
                      See All
                    </Link>
                  </div>
                  <Button
                    text="View vacancies"
                    color={"yellow"}
                    className={styles.footerButton}
                    onClick={handleOnClickViewVacancies}
                  />
                </div>
              </m.div>
            </m.div>
          </div>
        </section>
      </div>
    </LazyMotion>
  )
}
