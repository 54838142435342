// extracted by mini-css-extract-plugin
export var backgroundVideo = "HomePage-module--backgroundVideo--938af";
export var container = "HomePage-module--container--0b4d6";
export var hiddenScrollIcon = "HomePage-module--hiddenScrollIcon--e144d";
export var information = "HomePage-module--information--de52d";
export var informationContact = "HomePage-module--informationContact--9b662";
export var informationContactButton = "HomePage-module--informationContactButton--48945";
export var informationDescription = "HomePage-module--informationDescription--937b0";
export var informationTitle = "HomePage-module--informationTitle--bb9de";
export var informationTitleBreak = "HomePage-module--informationTitleBreak--b84b9";
export var informationTitleColored = "HomePage-module--informationTitleColored--78bee";
export var informationTitleFirstLine = "HomePage-module--informationTitleFirstLine--89cb0";
export var page = "HomePage-module--page--60415";
export var scrollIcon = "HomePage-module--scrollIcon--f3ba0";
export var wrapper = "HomePage-module--wrapper--535d9";