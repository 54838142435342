import React from "react"

export const Monitoring = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.4 1.4a2 2 0 00-2 2v21.2a2 2 0 002 2h21.2a2 2 0 002-2V3.4a2 2 0 00-2-2H3.4zm6.4 14H7v7h2.8v-7zm2.8-4.2h2.8v11.2h-2.8V11.2zM21 5.6h-2.8v16.8H21V5.6z"
      clipRule="evenodd"
    />
  </svg>
)
