import React from "react"
import { m } from "framer-motion"
import * as styles from "../MobileColumns.module.scss"

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
}
const thumbColorsVariants = {
  active: {
    color: "#000000",
  },
  inActive: {
    color: "#657097",
  },
}
interface ThumbProps {
  thumb: string
  isSelected: boolean
  onClick: () => void
}
export const Thumb:React.FC<ThumbProps> = ({ thumb, isSelected, onClick }) => {
  return (
    <m.div
      onClick={onClick}
      className={styles.thumb}
      variants={thumbColorsVariants}
      animate={isSelected ? "active" : "inActive"}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {thumb}
      {isSelected && (
        <m.div
          layoutId="underline"
          className={styles.underline}
          initial={false}
          animate={{ borderColor: "#471EEB" }}
          transition={spring}
        />
      )}
    </m.div>
  )
}
