// extracted by mini-css-extract-plugin
export var background = "RemoteTeamsPage-module--background--7acfd";
export var container = "RemoteTeamsPage-module--container--4f329";
export var footer = "RemoteTeamsPage-module--footer--5a104";
export var footerButton = "RemoteTeamsPage-module--footerButton--a3c2d";
export var footerLink = "RemoteTeamsPage-module--footerLink--674a2";
export var footerText = "RemoteTeamsPage-module--footerText--d6359";
export var headings = "RemoteTeamsPage-module--headings--f4cb4";
export var headingsButton = "RemoteTeamsPage-module--headingsButton--abb5b";
export var headingsInformation = "RemoteTeamsPage-module--headingsInformation--ba622";
export var headingsInformationBreak = "RemoteTeamsPage-module--headingsInformationBreak--7d36e";
export var headingsMain = "RemoteTeamsPage-module--headingsMain--19724";
export var headingsMainBreak = "RemoteTeamsPage-module--headingsMainBreak--f1ea3";
export var headingsSecondary = "RemoteTeamsPage-module--headingsSecondary--f9a36";
export var main = "RemoteTeamsPage-module--main--a94b6";
export var project = "RemoteTeamsPage-module--project--cb8c9";
export var projectCards = "RemoteTeamsPage-module--projectCards--6a786";
export var projects = "RemoteTeamsPage-module--projects--357e0";
export var projectsButton = "RemoteTeamsPage-module--projects-button--0845f";