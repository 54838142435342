import React from "react"
import { FirstCol, FourthCol, SecondCol, ThirdCol } from "./index"

export const Columns = [FirstCol, SecondCol, ThirdCol, FourthCol]
export const tableLineHeadings: string[] = [
  "Skills Growth",
  "Career Upside",
  "Salary & Rewards",
  "Work / Life Balance",
  "Stability",
  "Opportunity to See the World",
]
