import React from "react"

interface ArrowProps {
    className: string
}

export const Arrow: React.FC<ArrowProps> = ({ className }) => (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none">
        <path d="M12 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L12 9Z" className={className} />
    </svg>
)
