import { domMax, LazyMotion } from "framer-motion"
import { navigate } from "gatsby"
import React, { useContext } from "react"
import Button from "../../Button"
import Notification from "../../Notification"
import SectionHeader from "../../SectionHeader"
import AdvantagesTable from "../AdvanagesTable"
import * as styles from "./JoinUsPage.module.scss"
import { remoteTeamsPageRoutesContext } from "../../../data/context"

export const JoinUsPage: React.FC = () => {
  const routes = useContext(remoteTeamsPageRoutesContext)

  const vacanciesButton = <Button
    text={"View vacancies"}
    color={"yellow"}
    onClick={() => navigate(routes.vacancies.to)}
  />

  return (
    <LazyMotion features={domMax}>
      <div className={styles.main} id="join-us">
        <section>
          <div className={styles.container}>
            <SectionHeader main="Why Join Our Team" secondary="Join Us" />
            <AdvantagesTable />
            <div className={styles.notification}>
              <Notification link={vacanciesButton} />
            </div>
          </div>
        </section>
      </div>
    </LazyMotion>
  )
}
