import React from "react"
import { Icon } from "../../AdvantageIndicator/AdvantageIndicator"
import { Column } from "./Column"

const columnDatas = [
  { "text": "Limited" },
  { "text": "Slow" },
  { "text": "OK" },
  { "icon": Icon.Cross },
  { "icon": Icon.Check },
  { "icon": Icon.Question },
]

export interface ColProps {
  isSelected: boolean
}

export const FirstCol: React.FC<ColProps> = ({ isSelected }) => {
  return (
    <>
      {isSelected && (
        <Column heading="Big Company" columnDatas={columnDatas} />
      )}
    </>
  )
}
