import React from "react"

export const Design = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2308 0H0v17.2308h5.42884c.51795-6.2783 5.52366-11.284 11.80196-11.80194V0z"
      fill="#fff"
    />
    <circle cx="18.3077" cy="18.3077" fill="#fff" r="9.69231" />
  </svg>
)
