import { AnimatePresence, m } from "framer-motion"
import React from "react"
import * as styles from "./ColumnLayout.module.scss"

interface ColumnLayoutProps {
  children: React.ReactNode
  heading: string
}

export const columnVariants = {
  hidden: {
    opacity: 0,
    x: "100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 15,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
}

export const ColumnLayout: React.FC<ColumnLayoutProps> = ({
  heading,
  children,
}) => {
  return (
    <AnimatePresence>
      <m.div
        className={styles.cell}
        variants={columnVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className={styles.heading}>{heading}</div>
        <div className={styles.container}>{children}</div>
      </m.div>
    </AnimatePresence>
  )
}
