import React from "react"

export const Cloud = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M28 16.4715c0 4.0409-3.2288 7.3285-7.1976 7.3285H5.72984C2.57035 23.8 0 21.1829 0 17.9659c0-3.163 2.48506-5.7463 5.57177-5.8318-.42993-4.02243 2.80497-7.93415 7.15803-7.93415 3.1491 0 5.8914 2.07341 6.8431 5.04979C23.9992 8.47344 28 11.9598 28 16.4715z"
      fill="#fff"
    />
  </svg>
)
