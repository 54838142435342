// extracted by mini-css-extract-plugin
export var background = "ContactUsPage-module--background--dd157";
export var backgroundImage = "ContactUsPage-module--backgroundImage--2570a";
export var btn = "ContactUsPage-module--btn--fe7ed";
export var contact = "ContactUsPage-module--contact--a926b";
export var contacts = "ContactUsPage-module--contacts--c7f12";
export var container = "ContactUsPage-module--container--87797";
export var emailLabel = "ContactUsPage-module--emailLabel--199b0";
export var emailLink = "ContactUsPage-module--emailLink--f6b47";
export var emails = "ContactUsPage-module--emails--f0832";
export var h2 = "ContactUsPage-module--h2--14cb9";
export var half = "ContactUsPage-module--half--31723";
export var locationItem = "ContactUsPage-module--locationItem--7f241";
export var main = "ContactUsPage-module--main--f74da";