import React from "react"
import { Icon } from "../../AdvantageIndicator/AdvantageIndicator"
import { Column } from "./Column"
import { ColProps } from "./FirstCol"

const columnDatas = [
  { "text": "Limited" },
  { "text": "OK" },
  { "icon": Icon.Cross },
  { "icon": Icon.Cross },
  { "icon": Icon.Cross },
  { "icon": Icon.Question }
]

export const SecondCol: React.FC<ColProps> = ({ isSelected }) => (
  <>
    {isSelected && (
      <Column heading="Startup" columnDatas={columnDatas} />
    )}
  </>
)
