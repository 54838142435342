import classNames from "classnames"
import React from "react"
import { Brain, Check, CheckWhite, Cross, Globe, Money, Question, Rocket, Scale, Verified } from "../Icons"
import * as styles from "./AdvantageIndicator.module.scss"

export enum Icon {
  Check = "Check",
  CheckWhite = "CheckWhite",
  Cross = "Cross",
  Question = "Question",
  Brain = "Brain",
  Rocket = "Rocket",
  Money = "Money",
  Scale = "Scale",
  Verified = "Verified",
  Globe = "Globe"
}

const IconComponentMap = {
  [Icon.Check]: Check,
  [Icon.CheckWhite]: CheckWhite,
  [Icon.Cross]: Cross,
  [Icon.Question]: Question,
  [Icon.Brain]: Brain,
  [Icon.Rocket]: Rocket,
  [Icon.Money]: Money,
  [Icon.Scale]: Scale,
  [Icon.Verified]: Verified,
  [Icon.Globe]: Globe
}

interface AdvantageIndicatorProps {
  icon: Icon
}

export const AdvantageIndicator: React.FC<AdvantageIndicatorProps> = ({ icon }) => {
  const IconComponent = IconComponentMap[icon]
  const styl = classNames(
    styles.icon,
    (icon === Icon.Cross || icon === Icon.Question) && styles.border
  )
  return (
    <div className={styl}>
      <IconComponent />
    </div>
  )
}
