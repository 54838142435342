import React from "react"
import { Icon } from "../../AdvantageIndicator/AdvantageIndicator"
import { Column } from "../Columns/Column"
import { Columns } from "../Columns/Columns"
import * as styles from "./DesktopColumns.module.scss"

const columnDatas = [
  { "icon": Icon.Brain, "text": "Skills Growth", "textClassName": styles.columnDataText },
  { "icon": Icon.Rocket, "text": "Career Upside", "textClassName": styles.columnDataText },
  { "icon": Icon.Money, "text": "Salary & Rewards", "textClassName": styles.columnDataText },
  { "icon": Icon.Scale, "text": "Work / Life Balance", "textClassName": styles.columnDataText },
  { "icon": Icon.Verified, "text": "Stability", "textClassName": styles.columnDataText },
  { "icon": Icon.Globe, "text": "Opportunity to see the world", "textClassName": styles.columnDataText },
]

export const DesktopColumns: React.FC = () => (
  <div className={styles.container}>
    <Column heading="Advantages" columnDatas={columnDatas} />
    {Columns.map((Column, i) => (
      <Column isSelected={true} key={i} />
    ))}
  </div>
)
