import React from "react"
import * as styles from "./TechnologyStack.module.scss"

export interface TechonologyStackProps {
    technologies: string[]
}

export const TechonologyStack: React.FC<TechonologyStackProps> = ({ technologies }) => {
    return (
        <>
            <h6 className={styles.title}>Technology stack</h6>
            <div className={styles.technologies}>
                {technologies.map(technology => technology).join(", ")}
            </div>
        </>
    )
}