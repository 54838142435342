import React from "react"

export const CheckWhite: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="12" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.1791 14.9104-3.13432-3.1343L6 12.8209 10.1791 17l8.9552-8.95522L18.0896 7l-7.9105 7.9104Z"
      fill="#471EEB"
    />
  </svg>
)
