import React from "react"

export const Question = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
  >
    <path
      d="M5.86719 9.04688H3.875c-.00521-.28646-.00781-.46094-.00781-.52344 0-.64584.10677-1.17709.32031-1.59375.21354-.41667.64062-.88542 1.28125-1.40625C6.10938 5.0026 6.49219 4.66146 6.61719 4.5c.19271-.25521.28906-.53646.28906-.84375 0-.42708-.17187-.79167-.51563-1.09375-.33854-.30729-.79687-.46094-1.375-.46094-.55729 0-1.02343.15886-1.39843.47656-.375.31771-.63281.80209-.77344 1.45313l-2.015625-.25c.057292-.93229.453125-1.72396 1.187495-2.375C2.75521.755208 3.72396.429688 4.92188.429688c1.26041 0 2.26302.330729 3.00781.992192.74479.65624 1.11719 1.42187 1.11719 2.29687 0 .48437-.13803.94271-.41407 1.375-.27083.43229-.85416 1.02083-1.75 1.76563-.46354.38541-.7526.69531-.86719.92968-.10937.23438-.15885.65365-.14843 1.25782ZM3.875 12V9.80469h2.19531V12H3.875Z"
      fill="#657097"
    />
  </svg>
)
