
export interface WayToWork {
    title: string
    details: string
}

export const waysToWork: WayToWork[] = [
    {
        title: "Remote Development Team",
        details: "Ensuring growth opportunity and flexibility for your R&D model"
    },
    {
        title: "Legacy System Modernization",
        details: "Enabling higher control and reduction of your R&D operational costs"
    },
    {
        title: "Startup Mode",
        details: "Finding competent talents in your area of expertise"
    }
] 