import React from "react"
import { ColumnData } from "../ColumnData/ColumnData"
import { ColumnLayout } from "../ColumnLayout/ColumnLayout"
import { ColumnDataProps } from "../ColumnData/ColumnData"

interface ColumnProps {
    heading: string
    columnDatas: ColumnDataProps[]
}

export const Column: React.FC<ColumnProps> = ({heading, columnDatas }) => (
    <>
        <ColumnLayout heading={heading}>
            {
                columnDatas.map(({ icon, text, textClassName }) =>
                    <ColumnData key={text} icon={icon} text={text} textClassName={textClassName} />
                )
            }
        </ColumnLayout>
    </>
)
