import { AnchorLink } from "gatsby-plugin-anchor-links"
import { paramCase } from "param-case"
import React from "react"
import { Arrow } from "../icons/Arrow"
import * as styles from "./WayToWork.module.scss"


interface WayToWorkProps {
    title: string
    details: string
}

export const WayToWork: React.FC<WayToWorkProps> = ({ title, details }) => {
    return (
        <AnchorLink to={`/services#${paramCase(title)}`}>
        <div className={styles.main}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.icon}>
                <Arrow className={styles.iconImage}/>
            </div>
            <div className={styles.details}>
                {details}
            </div>
        </div>
        </AnchorLink>
    )
}